
import { PropType, defineComponent, nextTick } from "vue";
import Database from "@/model/Database";
import * as Lang from "@/i18n/lang";
import SeTab from "../global/SeTab.vue";
import Tag from "@/model/Tag";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Store from "@/model/Store";
import { DocumentReference } from "firebase/firestore";
import EmployeeRole from "@/model/EmployeeRole";
import SeDropdownItem from "../global/dropdown/SeDropdownItem.vue";
import { getTrainingState } from "@/pinia/trainings/TrainingState";
import { TrainingTargetType } from "./TrainingTargetType";

export default defineComponent({
  expose: ["applyFilters", "displayFilters"],

  data() {
    return {
      TrainingTargetType: TrainingTargetType,
      Lang: Lang,
      state: getTrainingState(),
      roles: [] as EmployeeRole[],
      targetType: TrainingTargetType.roles,
    };
  },

  async mounted() {
    this.roles = await getDatabaseManager().getEmployeeRoles(this.database);
  },
  props: {
    database: {
      type: Database,
      required: true,
    },
  },
  methods: {
    displayFilters() {
      var refs = <any>this.$refs;

      if (this.state.training!.target.employee_roles.length > 0) {
        this.targetType = TrainingTargetType.roles;

        var roles = this.state.training!.target.employee_roles.map((x) =>
          this.roles.find((y) => y.ref.id == x.id)
        );

        console.log(refs.roles);

        nextTick(() => {
          refs.roles.setSelectedItems(roles);
        });
      } else {
        this.targetType = TrainingTargetType.everyone;
      }
    },
    applyFilters() {
      var refs = <any>this.$refs;

      this.state.training!.target.employee_roles = [];

      if (this.targetType == TrainingTargetType.roles) {
        var roles: EmployeeRole[] = refs.roles.getSelectedItems();
        this.state.training!.target.employee_roles = roles.map((x) => x.ref!);
      }
    },
  },
});
