
import { defineComponent, nextTick, PropType, ref, watchEffect } from "vue";
import SeImage from "./SeImage.vue";
import { Zoompinch } from "zoompinch";
import "zoompinch/style.css";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { SeImage, Zoompinch },

  data() {
    const transform = {
      x: 0,
      y: 0,
      scale: 100,
      rotate: 0,
    };
    const offset = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    };

    return {
      offset: offset,
      transform: transform,
      displayed: null as string | null,
    };
  },
  props: {
    pictures: {
      type: Array as PropType<string[]>,
      default: [],
      required: true,
    },
    onPictureError: {
      type: Function,
      default: null,
      required: false,
    },
  },
  methods: {
    closeDisplayed() {
      this.displayed = null;
    },
    openImage($event: any) {
      this.displayed = $event.target.src;

      nextTick(() => {
        var refs = <any>this.$refs;

        let imageWidth = refs.zoompinchRef.$el.offsetWidth;

        this.offset.left = window.innerWidth / 2 - 200;

        this.offset.top = 100;

        console.log(imageWidth);
      });
    },
    close() {
      var element = this.$el;
      element.parentNode.parentNode.removeChild(element.parentNode);
    },
  },
});
