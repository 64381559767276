import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import Audit from "@/model/Auditing/Audit";
import * as StringUtils from "@/utils/StringUtils";
import { Role } from "@/model/User";
import { getAuditManager } from "@/firebase/AuditManager";
import { DateTime } from "rrule/dist/esm/datetime";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import Quiz, { QuizQuestion } from "@/model/Communication/Quiz";
import * as DateUtils from "@/utils/DateUtils";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Employee from "@/model/Employee";
import Store from "@/model/Store";
import Training from "@/model/Trainings/Training";
import TrainingResult from "@/model/Trainings/TrainingResult";
import { getTrainingManager } from "@/firebase/TrainingManager";

interface TrainingStatsState {
  training: Training | null;
  results: SnapshotListener<TrainingResult> | null;
  pageIndex: number;
  employees: Employee[];

  visibleStores: Store[];

  visibleResults: TrainingResult[];
}

export const getTrainingStatsState = defineStore("TrainingStatsState", {
  state: (): TrainingStatsState => {
    return {
      training: null,
      results: null,
      pageIndex: 0,
      employees: [],
      visibleStores: [],
      visibleResults: [],
    };
  },
  actions: {
    set(training: Training | null = null) {
      this.training = training;
    },

    loadEmployees(): void {
      for (let result of this.visibleResults) {
        if (this.employees.some((x) => x.ref.id == result.employee.id)) {
          continue;
        }
        getEmployeeManager()
          .getEmployee(result.employee)
          .then((employee: Employee) => {
            this.employees.push(employee);
          });
      }
    },

    async setup() {
      await getStoreManager().initialize();

      this.results = await getTrainingManager().getTrainingResusts(
        this.training!
      );

      await this.results.ensureInit();

      this.visibleStores = [] as Store[];

      for (let result of this.results.items) {
        if (!this.visibleStores.some((x) => x.ref.id == result.store.id)) {
          var store: Store = getStoreManager().getStoreFromCacheOnly(
            result.store.id
          );

          if (store != null) {
            this.visibleStores.push(store);
          }
        }
      }
    },

    getResults() {
      let myStores = getStoreManager()
        .getStores(true)
        .map((x) => x.ref.id!);

      let results = this.results?.items.filter(
        (x) => myStores.includes(x.store.id) && x.completed_timestamp != null
      );

      return results!;
    },

    getTotalViews() {
      return this.getResults().filter((x) => x.completed_timestamp != null)
        .length;
    },
    getTotalViewsToday() {
      var date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      var dateEnd = new Date();
      dateEnd.setHours(23);
      dateEnd.setMinutes(59);
      dateEnd.setSeconds(59);

      return this.getResults().filter(
        (x) =>
          x.completed_timestamp != null &&
          x.completed_timestamp >= date &&
          x.completed_timestamp <= dateEnd
      ).length;
    },
    getMedianTimespent() {
      var items = this.getResults().filter((x) => x.time_spent != null)!;

      if (items.length == 0) {
        return DateUtils.formatTimeWithSecondsFromSeconds(0);
      }

      var timeSpentArray = items
        .map((item) => item.time_spent!)
        .sort((a, b) => a - b);

      var median;
      var midIndex = Math.floor(timeSpentArray.length / 2);

      if (timeSpentArray.length % 2 === 0) {
        // If even, average the two middle values
        median = (timeSpentArray[midIndex - 1] + timeSpentArray[midIndex]) / 2;
      } else {
        // If odd, take the middle value
        median = timeSpentArray[midIndex];
      }
      return DateUtils.formatTimeWithSecondsFromSeconds(median);
    },
  },
});
