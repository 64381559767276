
import NavPanel from "../nav/NavPanel.vue";
import NavHeader from "../nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import DistributorsList from "../components/distributors/DistributorList.vue";
import { getDistributorManager } from "@/firebase/DistributorManager";
import { defineComponent } from "@vue/runtime-core";
import Distributor from "@/model/Distributor";
import * as Lang from "@/i18n/lang";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import { getDistributorsState } from "@/pinia/distributors/DistributorsState";
import { SnapshotListener } from "@/utils/SnapshotListener";
import * as Firebase from "@/firebase/Firebase";
import { collection, query, doc } from "firebase/firestore";
import { getStoreManager } from "@/firebase/StoreManager";
import Database from "@/model/Database";

export default defineComponent({
  components: {
    DistributorsList,
  },

  async setup() {
    let state = getDistributorsState();
    state.setup();

    return { state: state };
  },

  async mounted() {
    let refs = this.$refs as any;
    refs.searchBar.setSearch(this.state.search);
  },
  methods: {
    searchChange(value: string) {
      this.state.search = value;
    },
    async addDistributor() {
      var refs = <any>this.$refs;

      let name = refs.name.getValue();
      let address = refs.address.getValue();
      let postalCode = refs.postalCode.getValue();
      let country = refs.countrySelect.getCountry();

      if (!refs.form.verify()) {
        return;
      }
      if (country == null) {
        return;
      }
      let countryCode = country.code;

      let city = refs.city.getValue();

      let distributor: Distributor = new Distributor(
        doc(collection(Firebase.firestore, "distributors")),
        true,
        address,
        postalCode,
        city,
        [countryCode],
        name,
        null,
        [],
        null
      );

      let database = new Database(
        doc(collection(Firebase.firestore, "databases")),
        "Indépendants",
        [],
        null,
        distributor.ref,
        [],
        [],
        true,
        true
      );

      await distributor.set();

      await database.set();

      refs.distributorModal.close();
    },
    openDistributorModal() {
      (<any>this.$refs).distributorModal.open();
    },
  },
  data() {
    return {
      distributorManager: getDistributorManager(),
      Lang: Lang,
    };
  },
});
