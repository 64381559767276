import { time } from "console";
import { Timestamp } from "firebase/firestore";

export default class ActivationHistory {
  public timestamp: Date;
  public activated: boolean;

  constructor(timestamp: Date, activated: boolean) {
    this.timestamp = timestamp;
    this.activated = activated;
  }
  toFirestore() {
    return {
      timestamp: this.timestamp,
      activated: this.activated,
    };
  }
  public static fromFirestore(data: any) {
    return new ActivationHistory(data.timestamp, data.activated);
  }
}
