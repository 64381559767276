
import { getUserManager } from "../firebase/UserManager";
import { defineComponent } from "vue";
import NavHeader from "../components/nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import * as Lang from "@/i18n/lang";
import AccountList from "../components/accounts/AccountList.vue";
import { getState } from "@/pinia/AppState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as DistributorManager from "@/firebase/DistributorManager";
import Distributor from "@/model/Distributor";
import User, { Role } from "@/model/User";
import { getAccountsState } from "@/pinia/accounts/AccountsState";
import SeDropdown from "@/components/global/dropdown/SeDropdown.vue";
import { DocumentReference, doc, collection } from "firebase/firestore";
import * as Modals from "@/utils/Modals";
import * as Snackbars from "@/utils/Snackbars";
import * as Firebase from "@/firebase/Firebase";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getStoreManager } from "@/firebase/StoreManager";
import { getDistributorManager } from "@/firebase/DistributorManager";
import Database from "@/model/Database";
import Store from "@/model/Store";

export default defineComponent({
  components: {
    AccountList,
    NavHeader,
    SeDropdown,
  },
  mounted() {
    let refs = this.$refs as any;
    refs.searchBar.setSearch(this.state.search);
  },
  methods: {
    searchChange(value: string) {
      this.state.search = value;
    },

    async addAccount() {
      let refs = <any>this.$refs;

      if (refs.createBtn.isLoading()) {
        return;
      }
      refs.createBtn.toggleLoading(true);
      let email = refs.email.getValue().toLowerCase();
      let password = refs.password.getValue();
      let passwordConf = refs.passwordConf.getValue();
      let name = refs.name.getValue();
      let surname = refs.surname.getValue();
      let phone = refs.phone.getValue();

      let distributorValue: Distributor | null = this.state.selectedDistributor;

      let distributor: DocumentReference | null = null;

      if (this.state.selectedRole == null) {
        refs.createBtn.toggleLoading(false);
        Snackbars.display("Select a role.");
        return;
      }

      if (
        this.state.selectedRole == Role.Distributor &&
        distributorValue == null
      ) {
        refs.createBtn.toggleLoading(false);
        Snackbars.display("Select a distributor.");
        return;
      }

      if (
        this.state.selectedRole == Role.Customer &&
        this.state.selectedStore == null
      ) {
        refs.createBtn.toggleLoading(false);
        Snackbars.display("Select a store.");
        return;
      }

      if (
        this.state.selectedRole == Role.Manager &&
        this.state.selectedDatabase == null
      ) {
        refs.createBtn.toggleLoading(false);
        Snackbars.display("Select a database.");
        return;
      }

      if (
        distributorValue != null &&
        this.state.selectedRole == Role.Distributor
      ) {
        distributor = distributorValue!.ref!;
      }

      if (password != passwordConf) {
        refs.stateLabel.style.display = "";
        refs.stateLabel.innerText = "The password do not match.";
        refs.createBtn.toggleLoading(false);
        return;
      }

      let result = await this.manager.createUser(
        name,
        surname,
        email,
        password,
        phone,
        distributor == null ? null : distributor?.id,
        this.state.selectedDatabase == null
          ? null
          : this.state.selectedDatabase.ref!.id,
        this.state.selectedStore == null
          ? null
          : this.state.selectedStore.ref!.id,
        this.state.selectedRole.toString()
      );

      if (result.status == "ok") {
        refs.accountModal.close();
      } else {
        refs.stateLabel.style.display = "";

        refs.stateLabel.innerText = result.status;
        refs.createBtn.toggleLoading(false);
      }
    },
    canCreateDistributor(): boolean {
      var state = getState();
      return (
        state.userData!.role == Role.Admin ||
        state.userData!.role == Role.Distributor
      );
    },
    async openAccountModal() {
      var refs = <any>this.$refs;
      this.state.selectedRole = null;
      this.state.selectedDistributor = null;
      refs.email.setValue("");
      refs.password.setValue("");
      refs.passwordConf.setValue("");
      refs.name.setValue("");
      refs.surname.setValue("");
      refs.phone.setValue("");

      await getDatabaseManager().initialize();
      await getStoreManager().initialize();
      await getDistributorManager().initialize();

      this.state.distributors = getDistributorManager().getDistributors();
      this.state.stores = getStoreManager().getStores(false);
      this.state.databases = getDatabaseManager().getDatabases();

      refs.accountModal.open();
    },
  },
  expose: ["listView"],
  async setup() {
    let state = getAccountsState();
    let manager = getUserManager();

    await state.setup();

    return {
      state: state,
      Role: Role,
      manager: manager,
    };
  },
  data() {
    return { Lang: Lang };
  },
});
