import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";

export default class Database extends SecureatSnapshot {
  name: string = "";
  hidden_modules: DocumentReference[] = [];
  illustration_url: string | null = null;
  distributor: DocumentReference | null = null;
  managers: DocumentReference[] = [];
  stores: DocumentReference[] = [];
  store_access: boolean;
  hidden : boolean;

  /* Transcient */

  constructor(
    ref: DocumentReference,
    name: string,
    hidden_modules: DocumentReference[],
    illustration_url: string | null,
    distributor: DocumentReference | null = null,
    managers: DocumentReference[] = [],
    stores: DocumentReference[] = [],
    store_access: boolean = false,
    hidden : boolean = false ,
  ) {
    super(ref);
    this.name = name;
    this.hidden_modules = hidden_modules;
    this.illustration_url = illustration_url;
    this.distributor = distributor;
    this.managers = managers;
    this.stores = stores;
    this.store_access = store_access;
    this.hidden = hidden;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Database {
    const data = snapshot.data()!;

    let result = new Database(
      snapshot.ref,
      data.name,
      data.hidden_modules == undefined ? [] : data.hidden_modules,
      data.illustration_url == undefined ? null : data.illustration_url,
      data.distributor == undefined ? null : data.distributor,
      data.managers == undefined ? [] : data.managers,
      (data.stores = undefined ? [] : data.stores),
      data.store_access == undefined ? false : data.store_access,
      data.hidden == undefined ? false : data.hidden,
    );

    return result;
  }

  public toFirestore() {
    return {
      name: this.name,
      hidden_modules: this.hidden_modules,
      illustration_url: this.illustration_url,
      distributor: this.distributor,
      managers: this.managers,
      stores: this.stores,
      store_access: this.store_access,
      hidden : this.hidden,
    };
  }

  public static clone(d: Database): Database {
    return new Database(
      d.ref,
      d.name,
      d.hidden_modules,
      d.illustration_url,
      d.distributor,
      d.managers.map((x) => x),
      d.stores.map((x) => x),
      d.store_access,
      d.hidden,
    );
  }
}
