import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = {
  style: {"width":"600px","max-width":"80vw"},
  class: "flex align-start g24"
}
const _hoisted_3 = { class: "flex align-start g24" }
const _hoisted_4 = { class: "row2" }
const _hoisted_5 = { class: "flex align-center" }
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeCountryDropdown = _resolveComponent("SeCountryDropdown")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_DistributorsList = _resolveComponent("DistributorsList")!
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!
  const _component_SeForm = _resolveComponent("SeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      title: _ctx.Lang.getI18N('distributors')
    }, null, 8, ["title"]),
    _createVNode(_component_SearchBar, {
      class: "sticky",
      ref: "searchBar",
      searchChange: _ctx.searchChange,
      newCallback: _ctx.openDistributorModal,
      btnText: _ctx.Lang.getI18N('new'),
      modelValue: _ctx.state.displayUnactives,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.displayUnactives) = $event))
    }, null, 8, ["searchChange", "newCallback", "btnText", "modelValue"]),
    _createVNode(_component_SeForm, {
      ref: "form",
      class: "container"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SeModal, {
          title: _ctx.Lang.getI18N('new'),
          ref: "distributorModal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_SeInput, {
                required: "",
                ref: "name",
                placeholder: _ctx.Lang.getI18N('name')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_SeInput, {
                required: "",
                ref: "address",
                placeholder: _ctx.Lang.getI18N('address')
              }, null, 8, ["placeholder"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SeInput, {
                required: "",
                ref: "postalCode",
                placeholder: _ctx.Lang.getI18N('postal-code')
              }, null, 8, ["placeholder"]),
              _createVNode(_component_SeInput, {
                required: "",
                ref: "city",
                placeholder: _ctx.Lang.getI18N('city')
              }, null, 8, ["placeholder"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.Lang.getI18N("country")) + ":", 1),
                _createVNode(_component_SeCountryDropdown, { ref: "countrySelect" }, null, 512)
              ])
            ]),
            _createVNode(_component_SeButton, {
              class: "modal-valid",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addDistributor())),
              style: {"flex-grow":"1","width":"100%"},
              icon: "/ico/plus.svg"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("create")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"]),
        (_openBlock(), _createBlock(_Suspense, null, {
          fallback: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_HalfCircleSpinner, {
                "animation-duration": 1000,
                size: 60,
                color: "#454eff"
              })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_DistributorsList, {
              unactives: _ctx.state.displayUnactives,
              distributors: _ctx.distributorManager.listener?.items!
            }, null, 8, ["unactives", "distributors"])
          ]),
          _: 1
        }))
      ]),
      _: 1
    }, 512)
  ]))
}