
import NavPanel from "../nav/NavPanel.vue";
import NavHeader from "../nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import DatabaseList from "@/components/databases/DatabaseList.vue";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import Database from "@/model/Database";
import { defineComponent } from "vue";
import { getState } from "@/pinia/AppState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as Lang from "@/i18n/lang";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import { Role } from "@/model/User";
import * as StringUtils from "@/utils/StringUtils";
import { getDatabasesState } from "@/pinia/databases/DatabasesState";
import { doc, collection } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { getDistributorManager } from "@/firebase/DistributorManager";

export default defineComponent({
  components: {
    DatabaseList,
  },

  async setup() {
    var state = getDatabasesState();

    await state.setup();

    let databaseManager = getDatabaseManager();

    await databaseManager.initialize();

    await getDistributorManager().initialize();

    return {
      databaseManager: databaseManager,
      state: state,
    };
  },
  expose: ["listView"],
  mounted() {
    let refs = this.$refs as any;
    refs.searchBar.setSearch(this.state.search);
  },
  methods: {
    isDistributor(): boolean {
      let role = getState().userData?.role;
      return role == Role.Admin || role == Role.Distributor;
    },
    sortDatabases(databases: Database[]) {
      databases = databases.sort((a, b) =>
        StringUtils.removeEmojis(a.name).localeCompare(
          StringUtils.removeEmojis(b.name)
        )
      );
      return databases.filter(x=> !x.hidden);
    },
    searchChange(value: string) {
      this.state.search = value;
    },
    async createDatabase() {
      let name = (<any>this.$refs).name.getValue();

      if (name.trim() == "") {
        return;
      }
      let database: Database = new Database(
        doc(collection(Firebase.firestore, "databases")),
        name,
        [],
        null
      );

      if (this.user?.role == Role.Distributor) {
        database.distributor = this.user.distributor;

        let distributor =
          await getDistributorManager().getDistributorByReference(
            database.distributor
          );
        distributor.databases.push(database.ref);

        await distributor.set();
      }
      await database.set();

      (<any>this.$refs.databaseModal).close();
    },
    async openDatabaseModal() {
      (<any>this.$refs).databaseModal.open();
    },
  },
  data() {
    var user = getState().userData!;

    let state = getDatabasesState();

    return {
      state: state,
      listView: false,
      Lang: Lang,
      user: user,
    };
  },
});
