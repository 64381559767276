import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";
import Quiz from "@/model/Communication/Quiz";
import * as DateUtils from "@/utils/DateUtils";
import CommunicationTarget from "../Communication/CommunicationTarget";
import TrainingSection from "./TrainingSection";

export default class Training extends SecureatSnapshot {
  active: boolean;
  user: DocumentReference;
  name: string;
  target: CommunicationTarget;

  sections: TrainingSection[];

  constructor(
    ref: DocumentReference,
    active: boolean,
    user: DocumentReference,
    name: string,
    target: CommunicationTarget,
    sections: TrainingSection[]
  ) {
    super(ref);
    this.active = active;
    this.user = user;
    this.name = name;
    this.target = target;
    this.sections = sections;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Training {
    const data = snapshot.data()!;

    let result = new Training(
      snapshot.ref,
      data.active,
      data.user,
      data.name,
      CommunicationTarget.fromFirestore(data.target),
      data.sections != null && data.sections != undefined
        ? data.sections.map((x) => TrainingSection.fromFirestore(x))
        : []
    );
    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      user: this.user,
      name: this.name,
      target: this.target.toFirestore(),
      sections: this.sections.map((x) => x.toFirestore()),
    };
  }
  public clone() {
    return new Training(
      this.ref,
      this.active,
      this.user,
      this.name,
      this.target.clone(),
      this.sections.map((x) => x.clone())
    );
  }
}
