import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grow" }
const _hoisted_2 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.trainings, (training) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: training.ref!.id
      }, [
        (training.active || _ctx.state.displayUnactives)
          ? (_openBlock(), _createBlock(_component_WideCard, {
              key: 0,
              active: training.active == undefined ? false : training.active,
              icon: "/ico/training-blue.svg",
              title: training.name
            }, {
              subtitle: _withCtx(() => []),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.myRole != _ctx.Role.Customer)
                    ? (_openBlock(), _createBlock(_component_SeFadeButton, {
                        key: 0,
                        onClick: () => _ctx.editTraining(training),
                        icon: "/ico/edit-blue.svg"
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_SeFadeButton, {
                    onClick: () => _ctx.viewTrainingStats(training),
                    icon: "/ico/stats.svg"
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 2
            }, 1032, ["active", "title"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}