
import { getDistributorManager } from "@/firebase/DistributorManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Distributor from "@/model/Distributor";
import { defineComponent } from "vue";

export default defineComponent({
  async mounted() {},

  methods: {},
  data() {
    return {
      distributors: [] as Distributor[],
    };
  },
});
