
import { defineComponent, nextTick, PropType, ref } from "vue";
import { getState } from "../../pinia/AppState";
import SeDropdownFilter from "../global/SeDropdownFilter.vue";
import ElementType from "@/model/ElementType";
import SeDropdownItem from "../global/dropdown/SeDropdownItem.vue";
import {
  collection,
  doc,
  DocumentReference,
  Firestore,
} from "firebase/firestore";
import * as Lang from "@/i18n/lang";
import * as Snackbars from "@/utils/Snackbars";
import * as ObjectUtils from "@/utils/ObjectUtils";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getElementManager } from "@/firebase/ElementManager";
import SeCheckBox from "../global/SeCheckBox.vue";
import * as Firebase from "@/firebase/Firebase";
import { getBulkStoreElementState } from "@/pinia/elements/BulkStoreElementState";
import StoreElement from "@/model/Elements/StoreElement";
import { getStoreElementState } from "@/pinia/elements/StoreElementState";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import * as DOMUtils from "@/utils/DOMUtils";
import * as ImageUtils from "@/utils/ImageUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as Storage from "@/firebase/Storage";
import * as Modals from "@/utils/Modals";
import * as LabelsManager from "@/firebase/LabelsManager";

export default defineComponent({
  methods: {
    pickIllustration($event: any, element: DatabaseElement) {
      DOMUtils.selectFileDialog((e: any) =>
        this.pickIllustrationFromFile(e.target.files[0], $event.target, element)
      );
    },
    async pickIllustrationFromFile(
      file: File,
      target: HTMLImageElement,
      element: StoreElement
    ) {
      let base64string = await ImageUtils.encodeFileToBase64(file);
      const dataUrl = `data:image/jpeg;base64,${base64string}`;
      target.src = dataUrl;

      let path =
        "stores/" +
        this.state.store.ref.id +
        "/element_pictures/" +
        element?.ref.id!;

      element.illustration_path = path;

      var strategy = await UploadManager.createStrategy(file, path, false);
      UploadManager.addFileToUpload(strategy);
    },
    openTypeModalCreation() {
      let refs = <any>this.$refs;
      refs.typeName.setValue("");
      refs.typeModal.open();
    },
    openEditTaskTypeModal(type: ElementType) {
      var refs = <any>this.$refs;

      refs.typeName.setValue(type.name);
      refs.typeModal.open(type);
    },
    async createOrEditStoreType() {
      let refs = <any>this.$refs;

      if (!refs.typeForm.verify()) {
        return;
      }

      let typeName = refs.typeName.getValue();

      if (refs.typeModal.context == null) {
        let newType = await ElementTypeManager.createStoreElementType(
          this.state.store!,
          typeName
        );
        this.state.types.push(newType);

        this.state.selectedType = newType;
      } else {
        let type = <ElementType>refs.typeModal.context;
        type.name = typeName;
        await ElementTypeManager.updateElementType(type);
      }
      refs.typeModal.close();
    },

    onSearchChange(value: string) {
      if (value) {
        this.state.search = value;

        this.state.displayedElements = this.state.elements.filter(
          (x: StoreElement) =>
            x.getName().toLowerCase().includes(this.state.search.toLowerCase())
        );
      }
    },
    onDisplayUnactiveChange(value: boolean) {
      this.state.displayedElements = this.state.elements.filter(
        (x) => x.getActive() || value
      );
    },
    openExportModal() {
      var refs = <any>this.$refs;

      refs.exportModal.open();
    },
    async exportElements() {
      var refs = <any>this.$refs;

      if (this.state.targetDatabase == null) {
        Snackbars.display("Aucun  établissement selectionnée.");
        return;
      }

      if (this.state.targetStore == this.state.targetStore) {
        Snackbars.display(
          "Impossible d'exporter les élements vers l'établissement en cours d'edition."
        );
        return;
      }

      if (this.checkedElements.length == 0) {
        Snackbars.display("Aucun élement selectionné.");
        return;
      }

      refs.exportModal.close();

      let res = await Modals.openYesNo(
        "Vous allez exporter " +
          this.checkedElements.length +
          " élements sur " +
          this.state.targetDatabase.name +
          ". Les pièces jointes ne sont pas prises en compte. Voulez vous continuer ?",
        "Confirmation",
        "Continuer",
        "Annuler"
      );

      if (res) {
        this.applyExport();
      }
    },
    applyExport() {},
    newElement() {
      var element = new StoreElement(
        doc(
          collection(Firebase.firestore, this.state.store.ref.path, "elements")
        ),
        true,
        null,
        null,
        false,
        0,
        0,
        Lang.getI18N("new-element"),
        false,
        false,
        false,
        false,
        null,
        false,
        0,
        0,
        false,
        0,
        [],
        [],
        null,
        null
      );

      this.state.elements.push(element);

      this.scrollBottom();
    },
    revertCoolingTemperature() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].cooling_temperature =
          baseElement.cooling_temperature;
        this.state.elements[i].cooling_temperature_lock =
          baseElement.cooling_temperature_lock;
      }
    },
    revertReheatingTemperature() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].reheating_temperature =
          baseElement.reheating_temperature;
        this.state.elements[i].reheating_temperature_lock =
          baseElement.reheating_temperature_lock;
      }
    },
    revertTemperatureControlModule() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].temperature_control_module_active =
          baseElement.temperature_control_module_active;
        this.state.elements[i].temperature_control_module_active_lock =
          baseElement.temperature_control_module_active_lock;
      }
    },
    revertMaximumPolarity() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].maximum_polarity = baseElement.maximum_polarity;
        this.state.elements[i].maximum_polarity_lock =
          baseElement.maximum_polarity_lock;
      }
    },

    revertOilModule() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].oils_module_active =
          baseElement.oils_module_active;
        this.state.elements[i].oils_module_active_lock =
          baseElement.oils_module_active_lock;
      }
    },
    revertTraceabilityModule() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].traceability_module_active =
          baseElement.traceability_module_active;
        this.state.elements[i].traceability_module_active_lock =
          baseElement.traceability_module_active_lock;
      }
    },
    revertLabelModule() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].labels_module_active =
          baseElement.labels_module_active;
      }
    },
    revertMaximumTemperature() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].maximum_temperature =
          baseElement.maximum_temperature;
      }
    },
    revertMinimumTemperature() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].minimum_temperature =
          baseElement.minimum_temperature;
      }
    },
    revertTemperatureModule() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];
      }
    },
    revertActive() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].active = baseElement.active;
      }
    },
    revertType() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].type = baseElement.type;
      }
    },
    revertImage() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].illustration_url = baseElement.illustration_url;
      }
    },
    revertName() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].name = baseElement.name;
      }
    },
    onCoolingTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.cooling_temperature_lock)
          element.cooling_temperature = value;
      }
    },

    onReheatingTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.reheating_temperature_lock)
          element.reheating_temperature = value;
      }
    },

    onTemperatureControlModuleChange(value: boolean) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.temperature_control_module_active_lock)
          element.temperature_control_module_active = value;
      }
    },

    onMaximumPolarityChange(value: number) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.maximum_polarity_lock)
          element.maximum_polarity = value;
      }
    },

    onOilModuleActiveChange(value: boolean) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.oils_module_active_lock)
          element.oils_module_active = value;
      }
    },

    onTraceabilityModuleActiveChange(value: boolean) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.traceability_module_active_lock)
          element.traceability_module_active = value;
      }
    },

    onLabelsModuleActiveChange(value: number) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.labels_module_active_lock)
          element.labels_module_active = value;
      }
    },

    onMaximumTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.maximum_temperature_lock)
          element.maximum_temperature = value;
      }
    },

    onMinimumTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.minimum_temperature_lock)
          element.minimum_temperature = value;
      }
    },

    applyGlobalCheck() {
      var refs = <any>this.$refs;

      let value = !refs.globalCheck.getValue();

      if (value) this.checkedElements = this.state.elements.map((x) => x);
      else this.checkedElements = [];
    },
    onTemperatureModuleChange(value: boolean) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.temperatures_module_active_lock)
          element.temperatures_module_active = value;
      }
    },

    onTypeChange(type: DocumentReference) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.type_lock) element.type = type;
      }
    },
    onNameChange(value: string) {
      for (let element of this.checkedElements) {
        if (!element.originValue?.name_lock) element.name = value;
      }
    },
    onActiveChange(value: boolean, element: StoreElement) {
      element.active = value;

      for (let element of this.checkedElements) {
        if (!element.originValue?.active_lock) element.active = value;
      }
    },
    onFilterChange(index: number) {
      if (index == 0) {
        this.state.displayColumns = ["type", "active"];
      } else if (index == 1) {
        this.state.displayColumns = [
          "temperatures",
          "minimum-temperature",
          "maximum-temperature",
        ];
      } else if (index == 2) {
        this.state.displayColumns = ["labels"];
      } else if (index == 3) {
        this.state.displayColumns = ["traceability"];
      } else if (index == 4) {
        this.state.displayColumns = ["oils", "maximum-polarity"];
      } else if (index == 5) {
        this.state.displayColumns = [
          "temperature_control",
          "reheating-temperature",
          "cooling-temperature",
        ];
      }
    },

    openElement(element: StoreElement) {
      this.state.preventReload = true;
      getStoreElementState().set(element, this.state.store);
      getNavigationState().navigate("storeElement");
    },
    async duplicate(element: StoreElement) {
      let cloned = StoreElement.duplicate(element, false);

      cloned.name += " (Copy)";

      let labels = await LabelsManager.getStoreLabelsByElement(
        this.state.store.ref.id!,
        element
      );

      for (let label of labels) {
        label.ref = doc(
          collection(Firebase.firestore, this.state.store!.ref.path, "labels")
        );
      }

      await LabelsManager.setLabels(cloned, labels);

      this.state.elements.push(cloned);

      this.scrollBottom();
    },
    scrollBottom() {
      var refs = <any>this.$refs;

      nextTick(() => {
        let container = refs.container.parentNode;
        container.scroll(0, container.scrollHeight);

        container = refs.container;
        container.scroll(0, container.scrollHeight);
      });
    },
    async save() {
      var refs = <any>this.$refs;

      refs.saveBtn.toggleLoading(true);

      let updatedElements = [] as StoreElement[];

      for (let i = 0; i < this.state.elements.length; i++) {
        if (i >= this.state.baseElements.length) {
          updatedElements.push(this.state.elements[i]);
          continue;
        }
        this.state.baseElements[i].ref = this.state.elements[i].ref;
        if (
          !ObjectUtils.compareObjects(
            this.state.elements[i],
            this.state.baseElements[i]
          )
        ) {
          updatedElements.push(this.state.elements[i]);
        }
      }

      await UploadManager.applyUploads();

      for (let ele of updatedElements) {
        if (ele.illustration_path != null) {
          ele.illustration_url = await Storage.getDownloadUrl(
            ele.getIllustrationPath()
          );
        }

        await ele.set();
      }

      this.state.baseElements = this.state.elements.map((x) =>
        StoreElement.clone(x)
      );

      Snackbars.display(
        updatedElements.length + " élement(s) ont été(s) mis a jour."
      );
      refs.saveBtn.toggleLoading(false);
    },
    onRowClick(element: StoreElement) {
      if (this.checkedElements.includes(element)) {
        let index = this.checkedElements.indexOf(element);
        this.checkedElements.splice(index, 1);
      } else {
        this.checkedElements.push(element);
      }
    },
    onScroll() {
      var refs = <any>this.$refs;

      var parent = refs.container;

      if (parent == null) {
        return;
      }

      if (this.oldParentScrollTop - parent.scrollTop < 0) {
        refs.stickContainer.style.top = this.distanceFromTop - 400 + "px";

        refs.tbody.style.top = this.distanceFromTop - 85 + "px";
      } else {
        refs.stickContainer.style.top = this.distanceFromTop - 83 + "px";
        refs.tbody.style.top = this.distanceFromTop + 48 + "px";
      }

      this.oldParentScrollTop = parent.scrollTop;
    },
  },
  mounted() {
    var refs = <any>this.$refs;

    refs.chips.setSelectedIndex(0);
    this.onFilterChange(0);

    refs.container.addEventListener("scroll", this.onScroll);

    var rect = refs.container.getBoundingClientRect();

    this.distanceFromTop = rect.top - window.scrollY;
  },
  data() {
    return {
      Lang: Lang,
      state: getBulkStoreElementState(),
      checkedElements: [] as StoreElement[],
      oldParentScrollTop: 0,
      distanceFromTop: 0,
    };
  },
  setup() {},
});
