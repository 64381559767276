import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_3 = {
  class: "flex col g0",
  style: {"white-space":"nowrap"}
}
const _hoisted_4 = { class: "text-muted" }
const _hoisted_5 = { class: "bold" }
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = { class: "bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.distributors, (distributor) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: distributor.id
      }, [
        (distributor.active || _ctx.unactives)
          ? (_openBlock(), _createBlock(_component_WideCard, {
              key: 0,
              active: distributor.active,
              title: distributor.name,
              icon: 
          distributor.logo == null ? '/ico/distributors2.svg' : distributor.logo
        ,
              click: () => _ctx.onDistributorClick(distributor)
            }, {
              subtitle: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", null, _toDisplayString(distributor.city), 1),
                  _createElementVNode("span", null, _toDisplayString(distributor.countries.join(",")), 1)
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getStoreCount(distributor)), 1),
                    _createTextVNode(" Etablissements")
                  ]),
                  _createElementVNode("span", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getStoreCountWithLicence(distributor)), 1),
                    _createTextVNode(" E. avec licence")
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["active", "title", "icon", "click"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}