
import * as Lang from "@/i18n/lang";
import { defineComponent } from "vue";
import WideCard from "@/components/global/WideCard.vue";
import * as Snackbars from "@/utils/Snackbars";
import * as DOMUtils from "@/utils/DOMUtils";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import Attachment from "@/model/Filesystem/Attachment";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as Modals from "@/utils/Modals";
import { getCommunicationsState } from "@/pinia/communications/CommunicationsState";
import CommunicationTargetRole from "@/components/communication/CommunicationTargetRole.vue";
import { getTrainingsState } from "@/pinia/trainings/TrainingsState";
import TrainingQuiz from "@/components/trainings/TrainingQuiz.vue";
import { getTrainingState } from "@/pinia/trainings/TrainingState";
import TrainingTargetStore from "@/components/trainings/TrainingTargetStore.vue";
import TrainingTargetRole from "@/components/trainings/TrainingTargetRole.vue";
import TrainingSection from "@/model/Trainings/TrainingSection";
import Quiz from "@/model/Communication/Quiz";

export default defineComponent({
  async setup() {
    let state = getTrainingState();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },

  mounted() {
    var refs = <any>this.$refs;

    if (
      this.state.training!.quiz != null &&
      this.state.training!.quiz.questions.length > 0
    ) {
      this.state.selectedQuestion = this.state.training!.quiz.questions[0];
    }
  },

  methods: {
    addSection() {
      this.state.training.sections.push(
        new TrainingSection("", new Quiz([]), null)
      );
    },
    downloadAttachment() {
      this.state.training?.attachment?.download();
    },
    openRoleFilterModal() {
      var refs = <any>this.$refs;
      refs.targetRole.displayFilters();
      refs.roleFilterModal.open();
    },
    closeRoleFilterModal() {
      var refs = <any>this.$refs;
      refs.targetRole.applyFilters();
      refs.roleFilterModal.close();
    },
    openStoreFilterModal() {
      var refs = <any>this.$refs;
      refs.targetStore.displayFilters();
      refs.storeFilterModal.open();
    },
    closeStoreFilterModal() {
      var refs = <any>this.$refs;
      refs.targetStore.applyFilters();
      refs.storeFilterModal.close();
    },
    uploadAttachment() {
      DOMUtils.attachementFileDialog(this.onAttachmentUploaded);
    },
    async onAttachmentUploaded(e: any) {
      let file = e.target.files[0];

      var path =
        "/databases/" +
        this.state.database?.ref.id! +
        "/training/" +
        this.state.training?.ref.id!;

      var strategy = await UploadManager.createStrategy(file, path, true);

      UploadManager.addFileToUpload(strategy);

      this.state.selectedSection!.attachment = strategy.generateAttachment()!;

      console.log(this.state.training);

      console.log(this.state.selectedSection);
    },

    async update() {
      let refs = <any>this.$refs;

      if (refs.modifyBtn.isLoading()) {
        return;
      }

      refs.modifyBtn.toggleLoading(true);

      if (this.state.selectedSection?.attachment == null) {
        refs.modifyBtn.toggleLoading(false);
        Modals.open("Please select an attachment for this training.");
        return;
      }

      if (this.state.training.quiz != null) {
        for (let question of this.state.training.quiz!.questions) {
          if (
            question.answers.length == 0 ||
            !question.answers.some((x) => x.correct)
          ) {
            refs.modifyBtn.toggleLoading(false);
            Modals.open(Lang.getI18N("training.no_valid_answer"));
            return;
          }
        }
      }

      await UploadManager.applyUploads();

      for (let section of this.state.training.sections) {
        if (section.attachment) {
          await section.attachment.generateUrl();
        }
      }

      await this.state.training?.set();

      if (this.state.creation) {
        getTrainingsState().trainings.push(this.state.training);
      }

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
  },
  components: {
    WideCard,
    TrainingQuiz,
    TrainingTargetStore,
    TrainingTargetRole,
  },
});
