
import User, { Role } from "@/model/User";
import { PropType, defineComponent } from "vue";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as Lang from "@/i18n/lang";
import { getAccountState } from "@/pinia/accounts/AccountState";
import Communication from "@/model/Communication/Communication";
import { CommunicationState } from "@/model/Communication/CommunicationState";
import { getCommunicationState } from "@/pinia/communications/CommunicationState";
import Database from "@/model/Database";
import { getCommunicationsState } from "@/pinia/communications/CommunicationsState";
import CommunicationStatus from "@/components/communication/CommunicationStatus.vue";
import { getCommunicationStatsState } from "@/pinia/communications/CommunicationStatsState";
import { getState } from "@/pinia/AppState";
import Training from "@/model/Trainings/Training";
import { getTrainingsState } from "@/pinia/trainings/TrainingsState";
import { getTrainingState } from "@/pinia/trainings/TrainingState";
import { getTrainingStatsState } from "@/pinia/trainings/TrainingStatsState";

export default defineComponent({
  components: {},
  data() {
    return {
      Role: Role,
      myRole: getState().userData!.role,
      Lang: Lang,
      state: getTrainingsState(),
    };
  },
  props: {
    database: {
      type: Database,
      required: true,
    },
  },
  methods: {
    viewTrainingStats(training: Training) {
      getTrainingStatsState().set(training);
      getNavigationState().navigate("trainingStats");
    },
    editTraining(training: Training) {
      getTrainingState().set(this.database, training);
      getNavigationState().navigate("training");
    },
  },
});
