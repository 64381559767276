import {
  getStorage,
  ref,
  uploadBytes,
  updateMetadata,
  getDownloadURL,
  deleteObject,
  listAll,
  getMetadata,
  StorageReference,
  uploadBytesResumable,
  uploadString,
  StringFormat,
  UploadResult,
  UploadTaskSnapshot,
} from "firebase/storage";
import CloudItem from "@/model/Filesystem/CloudItem";
import { CloudItemType } from "@/model/Filesystem/CloudItemType";

import * as Firebase from "@/firebase/Firebase";

export async function createFolder(path: string) {
  const storage = getStorage();

  let folderRef = ref(storage, path + "/.metadata");

  uploadString(folderRef, "");
}
export function createVideoLinkfile(
  path: string,
  filename: string,
  url: string,
  videoId: string
) {
  const storage = getStorage();

  let folderRef = ref(storage, path + "/" + filename + ".vid");

  var metadata = {
    customMetadata: {
      url: url,
      videoId: videoId,
    },
  };
  uploadString(folderRef, "", StringFormat.RAW, metadata);
}
export async function applyLock(item: CloudItem) {
  try {
    let ref = getRef(item.path, item.type);

    let metadata = await getMetadata(ref);

    if (metadata.customMetadata == undefined) {
      metadata.customMetadata = {};
    }
    metadata.customMetadata!.locked! = item.locked.toString();

    await updateMetadata(ref, metadata);
  } catch {
    if (item.type == CloudItemType.Folder) {
      await createFolder(item.path);
      await applyLock(item);
    }
  }
}
export async function rename(path: string, type: CloudItemType, name: string) {
  let ref = getRef(path, type);

  let metadata = await getMetadata(ref);

  if (metadata.customMetadata == undefined) {
    metadata.customMetadata = {};
  }

  metadata.customMetadata!.name = name;

  await updateMetadata(ref, metadata);
}
export async function deleteRef(path: string, type: CloudItemType) {
  if (type == CloudItemType.Folder) {
    await recursiveDelete(path);
  } else {
    let storageRef = getRef(path, type);
    await deleteObject(storageRef);
  }
}

export async function recursiveDelete(path: string) {
  var folderRef = ref(getStorage(), path);

  var result = await listAll(folderRef);

  for (let item of result.items) {
    await deleteObject(item);
  }

  for (let prefix of result.prefixes) {
    await recursiveDelete(prefix.fullPath);
  }
}
function getRef(path: string, type: CloudItemType) {
  const storage = getStorage();
  if (type == CloudItemType.Folder) {
    path += "/.metadata";
  }

  return ref(storage, path);
}
export async function getDownloadLink(path: string): Promise<string> {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  return await getDownloadURL(storageRef);
}

export function getPathFromUrl(url: string) {
  const storage = getStorage();
  return ref(storage, url).fullPath;
}
export function uploadFile(
  path: string,
  file: any,
  fileContent: any,
  progressNotifier: Function,
  useFileName: boolean
): Promise<UploadResult> {
  const storage = getStorage();
  const storageRef = ref(storage, path + (useFileName ? "/" + file.name : ""));
  const metadata = {
    contentType: file.type,
    contentDisposition: "inline", // or 'attachment; filename="filename.pdf"' for download
  };

  let uploadTask = uploadBytesResumable(storageRef, fileContent, metadata);
  return new Promise((resolve, reject) => {
    const unsubscribe = uploadTask.on(
      "state_changed",
      (snapshot: UploadTaskSnapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressNotifier(progress);
      },
      (error) => {
        unsubscribe();
        reject(error);
      },
      () => {
        unsubscribe();
        resolve(uploadTask.snapshot);
      }
    );
  });
}
export async function getDocuments(path: string): Promise<CloudItem[]> {
  const storage = getStorage();

  const storageRef = ref(storage, path);

  let cloudItems: CloudItem[] = [];

  let results = await listAll(storageRef);

  for (let prefix of results.prefixes) {
    let list = await listAll(prefix);

    let size = 0;

    size += list.prefixes.length;
    size += list.items.filter((x) => !x.name.startsWith(".")).length;

    let folder = new CloudItem(
      prefix.name,
      CloudItemType.Folder,
      prefix.fullPath,
      "/ico/folder2.svg",
      size
    );
    cloudItems.push(folder);
  }

  for (let item of results.items) {
    if (!item.name.startsWith(".")) {
      let file = new CloudItem(
        item.name,
        CloudItemType.File,
        item.fullPath,
        "/ico/file.svg"
      );

      if (file.name.includes(".vid")) {
        file.name = file.name.replace(".vid", "");
        file.isVideo = true;
      }
      cloudItems.push(file);
    }
  }

  for (let result of cloudItems) {
    let reference = getRef(result.path, result.type);

    try {
      let metadata = await getMetadata(reference);

      if (metadata.customMetadata != undefined) {
        if (metadata.customMetadata.name != undefined) {
          result.name = metadata.customMetadata.name;
        }
        if (metadata.customMetadata.locked == "true") {
          result.locked = true;
        }
      }
    } catch {
      console.log("No .metadata for " + result.path);
    }
  }

  return cloudItems;
}
