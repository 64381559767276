
import * as Lang from "@/i18n/lang";
import { getUserManager } from "../firebase/UserManager";
import { getStoreManager } from "../firebase/StoreManager";
import Store from "../model/Store";
import User, { Role } from "../model/User";
import { getState } from "../pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { defineComponent } from "vue";
import * as DistributorManager from "../firebase/DistributorManager";
import WideCard from "../components/global/WideCard.vue";
import Distributor from "@/model/Distributor";
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";

import { getAccountState } from "@/pinia/accounts/AccountState";
import { getAuth, updatePassword } from "firebase/auth";

export default defineComponent({
  async setup() {
    let navigationState = getState();
    let state = getAccountState();

    let stores = [] as Store[];

    let user = getAppState().userData!;

    if (user.role == Role.Admin) {
      stores = await getStoreManager().getStoresOfUser(state.selectedAccount!);
    } else {
      //  stores = await StoreManager.getStores(user, false);
    }

    return {
      Role: Role,
      state: state,
      user: user,
      stores: stores,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  mounted() {},
  methods: {
    async disconnectForPasswordChange() {
      this.user.new_password_required = true;

      await this.user.set();

      let auth = getAuth();
      this.disconnect = true;
      this.state.logoutRequest = true;
      getAppState().token = null;
      await auth.signOut();
      this.state.logoutRequest = false;
      location.reload();
    },
    openPasswordModal() {
      var refs = <any>this.$refs;
      refs.pwdModal.open();
    },
    async onIllustrationChange(event: ProgressEvent, file: File) {
      await Storage.uploadAccountIllustration(
        this.state.selectedAccount!,
        file
      );

      this.state.selectedAccount!.illustration_url =
        await Storage.getAccountIllustrationUrl(this.state.selectedAccount!);

      await this.state.selectedAccount!.set();
    },
    async updateAccount() {
      let refs = <any>this.$refs;
      refs.modifyBtn.toggleLoading(true);

      await this.state.selectedAccount?.set();

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
  },
  components: { WideCard },
});
