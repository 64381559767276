import { collection, query, where, getDocs } from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Element from "../model/Elements/Element";
import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getStoreManager } from "@/firebase/StoreManager";
import Tag from "@/model/Tag";
import Communication from "@/model/Communication/Communication";
import { getDatabaseManager } from "./DatabaseManager";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import Training from "@/model/Trainings/Training";
import TrainingResult from "@/model/Trainings/TrainingResult";

interface TrainingManager {}

export const getTrainingManager = defineStore("TrainingManager", {
  state: (): TrainingManager => {
    return {};
  },
  actions: {
    async getTrainings(database: Database): Promise<Training[]> {
      const q = query(
        collection(Firebase.firestore, database.ref.path + "/trainings")
      );

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => Training.fromFirestore(x));
    },

    async getTrainingResusts(training: Training) {
      let path = training.ref.path + "/results";

      const q = query(
        collection(Firebase.firestore, path),
        where("completed_timestamp", "!=", null)
      );

      return new SnapshotListener<TrainingResult>(
        TrainingResult.fromFirestore,
        q
      );
    },
  },
});
