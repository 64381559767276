export const I18N_FR = new Map([
  ["register", "Pas encore inscrit ? Rejoignez nous"],
  ["login", "Connexion"],
  ["password", "Mot de passe"],
  ["home", "Accueil"],
  ["distributors", "Distributeurs"],
  ["databases", "Base de données"],
  ["database", "Base de donnée"],
  ["tasks", "Tâches"],
  ["stores", "Établissements"],
  ["probes", "Sondes"],
  ["search", "Recherche"],
  ["new", "Ajouter"],
  ["today", "Aujourd'hui"],
  ["yesterday", "Hier"],
  ["this-week", "Cette semaine"],
  ["done", "Terminé"],
  ["on-time", "À temps"],
  ["postponed", "Reporté"],
  ["late", "En retard"],
  ["address", "Adresse"],
  ["postal-code", "Code postal"],
  ["city", "Ville"],
  ["country", "Pays"],
  ["open", "Ouvrir"],
  ["schedule", "Horaires"],
  ["yearly", "Annuel"],
  ["monthly", "Mensuel"],
  ["weekly", "Hebdomadaire"],
  ["daily", "Journalier"],
  ["apply", "Appliquer"],
  ["name", "Nom"],
  ["surname", "Prénom"],
  ["owners", "Propriétaires"],
  ["add", "Ajouter"],
  ["modify", "Modifier"],
  ["rooms", "Pièces"],
  ["equipement", "Équipements"],
  ["all", "Tous"],
  ["working", "Fonctionnel"],
  ["not-working", "Non fonctionnel"],
  ["phone-number", "Numéro de téléphone"],
  ["customer", "Client"],
  ["distributor", "Distributeur"],
  ["admin", "Administrateur"],
  ["manager", "Manager"],
  ["managers", "Managers"],
  ["accounts", "Comptes"],
  ["billing", "Factures"],
  ["support", "Support"],
  ["add", "Ajouter"],
  ["apply", "Appliquer"],
  ["temperatures", "Relevé de températures"],
  ["labels", "Étiquettes"],
  ["traceability", "Tracabilité"],
  ["edit", "Editer"],
  ["task-schedule", "Planification de la tâche"],
  ["task-configuration", "Configuration de tache"],
  ["new-room", "Créer"],
  ["active", "Actif"],
  ["inactive", "Inactif"],
  ["start", "Début"],
  ["end", "Fin"],
  ["enter-the-email-of-the-user", "Entrer l'adresse mail de l'utilisateur"],
  ["add-owner", "Ajouter un propriétaire"],
  ["manage", "Gérer"],
  ["assigned-to", "Attribué à"],
  ["activate-module", "Activer le module"],
  [
    "content- not-available",
    "Ce contenu n'est pas disponible pour le moment ! Revenez plus tard",
  ],
  ["logout", "Deconnexion"],
  ["actions", "Tâches diverses"],
  ["action", "Tâches diverses"],

  ["requires-picture", "Photo requise"],
  ["requires-manager", "Requiers un manager"],
  ["oils", "Relevé des huiles"],
  ["elements", "Éléments"],
  ["new-task", "Nouvelle tâche"],
  ["new-element", "Nouvel élement"],
  ["field-locked", "Ce champ est verrouillé"],
  ["field-unlocked", "Ce champ est déverrouillé"],
  ["minimum-temperature", "Température min"],
  ["maximum-temperature", "Température max"],
  ["probe-url", "URL de la sonde"],
  ["picture", "Image"],
  ["delivery_item", "Réception de merchandise"],
  ["maximum-polarity", "Polarité max"],
  ["polarity", "Polarité"],
  ["action-name", "Nom de l'action"],
  ["every", "Tous les"],
  ["day(s)", "jour(s)"],
  ["week(s)", "semaine(s)"],
  ["month(s)", "mois"],
  ["year(s)", "an(s)"],
  ["monday", "Lundi"],
  ["tuesday", "Mardi"],
  ["wednesday", "Mercredi"],
  ["thursday", "Jeudi"],
  ["friday", "Vendredi"],
  ["saturday", "Samedi"],
  ["sunday", "Dimanche"],
  ["first", "Premier"],
  ["second", "Second"],
  ["third", "Troisième"],
  ["fourth", "Quatrième"],
  ["on-day", "Le"],
  ["on-the", "Le"],

  ["defrosting", "Décongélation"],
  ["expiration", "DLC"],
  ["simple-name", "Nom simple"],
  ["employee", "Employé"],
  ["expected-date", "Échéance"],
  ["submission-date", "Date de soumission"],
  ["cleaned", "Nettoyé"],
  ["oil-changed", "Huile changée"],
  ["task-done", "Effectuée(s)"],
  ["task-late", "En retard"],
  ["task-future", "A venir"],
  ["no-task-today", "Aucune tâche pour aujourd'hui."],
  ["store-database", "Base de donnée de l'établissement"],
  [
    "task-action-required",
    "Veuillez ajouter au moins une action pour la tâche",
  ],
  ["password-conf", "Confirmation de mot de passe"],
  ["countries", "Pays"],
  ["new-label", "Nouvelle étiquette"],
  ["edit-label", "Editer l'étiquette"],
  ["history", "Historique"],
  ["expend", "Développer tout"],
  ["reduce", "Réduire tout"],
  ["cumulative-expirations", "Cumuler les durées"],
  ["expiration-duration", "Durée DLC"],
  ["defrosting-duration", "Durée de décongélation"],
  ["singleton-name", "Nom seul"],
  [
    "invalid-temperature-input",
    "La température maximale pour le module de température </br> doit être supérieure ou égale à la température minimale.",
  ],
  ["temperature", "Température"],
  ["comment", "Commentaire"],
  ["refrigeration", "Réfrigiration"],
  ["documents", "Documents"],
  ["save", "Sauvegarder"],
  ["secureat-licence", "Licence secureat"],
  ["no-elements", "Aucun élements"],
  [
    "schedule-incoherent",
    "L'heure de début doit être antérieure à l'heure de fin",
  ],
  ["hourless_expiration", "DLC (sans heures)"],
  ["customer_expiration", "DLC (client)"],
  ["download", "Télécharger"],
  ["rename", "Renommer"],
  ["delete", "Supprimer"],
  ["share", "Partager"],
  ["subtasks", "Sous tâches"],
  ["close", "Fermer"],
  ["count", "Comptage"],
  ["new-folder", "Nouveau dossier"],
  ["back", "Retour"],
  [
    "unsaved-changes",
    "La page contient des modifications qui n'ont pas été enregistrés, êtes-vous sur de vouloir revenir en arrière ?",
  ],
  ["form-invalid", "Veuillez remplir tous les champs"],
  ["user-not-found", "L'utilisateur n'a pas été trouvé"],
  ["required-field", "Veuillez remplir ce champ."],
  ["cancel", "Annuler"],
  ["yes", "Oui"],
  ["no", "Non"],
  ["duplicate", "Dupliquer"],
  ["freeform_expiration", "DLC (avec texte)"],
  ["general", "Général"],
  ["temperature_control", "Mise en température"],
  ["auditing", "Audit"],
  ["published", "Publier"],
  ["structure", "Structure"],
  ["new-section", "Nouvelle section"],
  ["new-question", "Nouvelle question"],
  ["question", "Question"],
  ["question.multiple_choice", "Choix multiples"],
  ["question.text_input", "Champ de saisie"],
  ["question.radio", "Choix unique"],
  ["question.file_upload", "Document"],
  ["question.rating", "Note"],
  ["question.yes_or_no", "Oui/Non"],
  ["question.number", "Nombre"],
  ["question.date", "Date"],
  ["options", "Options"],
  ["limit-characters", "Limiter la taille du texte"],
  ["max-characters", "Nombre maximal de caractères"],
  ["score", "Score"],
  ["preview", "Aperçu"],
  ["flutter-embedding", "Flutter embedding"],
  ["time-target-temperature", "Temps maximal de mise en température"],
  ["target-temperature", "Température cible"],
  ["postpone-date", "Date de report"],
  ["select-all", "Selectionner tout"],
  ["ask-domhygiene", "Aucune tâche trouvée sur cette période."],
  ["lost-password", "Mot de passe oublié ? Cliquez ici"],
  [
    "login.recovery.help",
    "Veuillez renseigner l'adresse email spécifiée a la création du compte.",
  ],
  ["login.recovery.send-mail", "Envoyer un mail de récupération"],
  [
    "login.recovery.error",
    "Impossible d'envoyer un email de récupération à ce mail. Veuillez specifier une adresse valide.",
  ],
  [
    "login.recovery.success",
    "Un mail de vérification a été envoyé à l'adresse spécifiée.",
  ],
  ["already-manager", "Ce compte gère déja cette base de donnée."],
  ["opening", "Ouverture"],
  [
    "target-require-customer",
    "Le compte ciblé doit être un compte 'client' et ne doit posséder aucun store. Veuillez créer un nouveau compte.",
  ],
  ["welcome-to-secureat", "Bienvenue sur le dashboard Secureat."],
  ["questions-empty", "Aucune questions"],
  ["add-new-section", "Ajouter une nouvelle section"],
  ["multiple-selection", "Selection multiple"],
  ["choices", "Propositions"],
  ["total-score", "Score total"],
  ["new-choice", "Nouvelle proposition"],
  ["no-options", "Aucune options"],
  ["allowed-extensions", "Format(s) autorisé(s)"],
  ["min-rating", "Note minimale"],
  ["max-rating", "Note maximale"],
  ["min-number", "Minimum"],
  ["max-number", "Maximum"],
  ["limit-current-date", "Limiter la date"],
  ["date.no_limit", "Aucune limite"],
  ["date.only_future", "Au future"],
  ["date.only_past", "Au passé"],
  ["results", "Résultats"],
  ["delete-dialog", "Supprimer"],
  [
    "delete-confirm",
    "Avertissement : Vous êtes sur le point de supprimer cet élément. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer ?",
  ],
  ["download-pdf", "Télécharger le PDF"],
  ["sections", "Sections"],
  ["questions", "Questions"],
  ["reheating-temperature", "Température de réchauffage"],
  ["cooling-temperature", "Température de refroidissement"],
  ["cooling", "Refroidissement"],
  ["reheating", "Rechauffage"],
  [
    "invalid-database",
    "La base de donnée n'existe pas ou est invalide : un distributeur doit être affectué a cette base.",
  ],
  ["preparations", "Préparations"],
  ["saved-successfully", "Les modifications ont bien été appliquées."],
  ["start-temperature", "Début"],
  ["end-temperature", "Fin"],
  ["temperature-control-duration", "Durée"],
  ["attachment", "Pièce jointe"],
  ["period", "Période"],
  ["add-condition", "Condition"],
  ["edit-condition", "Modifier la condition"],
  ["choose-question", "Sélectionner la condition"],
  ["question-condition", "Afficher si"],
  ["create", "Créer"],
  ["no-parameters", "Ce module ne possède pas de paramètres."],
  ["import", "Importer"],
  ["export", "Exporter"],
  [
    "documents.file-exists",
    "Ce fichier existe déja, supprimez ou renommez le, puis ressayez.",
  ],
  ["imported-successfully", "Données importées avec succès"],
  ["import-error", "Une erreur s'est produite lors de l'importation"],
  ["user-already-owns-store", "L'utilisateur possède déja cet établissement"],
  [
    "unable-admin-assign-store",
    "Impossible d'assigner un établissement à un compte administrateur",
  ],
  [
    "unable-manager-assign-store",
    "Impossible d'assigner un établissement à un compte manager",
  ],
  [
    "email.invitation.subject",
    "Invitation à devenir propriétaire de établissement",
  ],
  ["email.invitation.body", "Vous avez été invité à posséder l'établissement"],
  ["email.invitation.body.login", "Connectez-vous à SecurEat maintenant"],
  ["email.invitation.body.register", "Créez votre compte SecurEat maintenant"],
  ["email.invitation.snackbar.sent", "L'invitation a été envoyée à"],
  [
    "form.store.invalid.exists",
    "Un magasin avec ce nom ou cette adresse existe déjà.",
  ],
  ["rating-precision", "Précision"],
  ["task-configuration.select-module", "Selectionnez un module"],
  ["firstname", "Nom"],
  ["password-confirm", "Confirmation mot de passe"],
  ["question-required", "Question requise"],
  ["display-unactives", "Afficher inactifs"],
  ["warning-dialog", "Avertissement"],
  [
    "audit-published-warning",
    "Attention : L'audit a déjà été publié. Veuillez être prudent, car des modifications à l'audit pourraient potentiellement perturber les réponses existantes.",
  ],
  ["audit-publish-confirmation", "Êtes-vous sûr de vouloir publier l'audit ?"],
  ["publish", "Publier"],
  ["duplicated-successfully", "Dupliqué avec succès."],
  ["store.select-database", "Sélectionner une base de données"],
  ["lock", "Verrouiller"],
  ["unlock", "Déverrouiller"],
  [
    "user-store-limit",
    "Un utilisateur ne peut pas posséder plus de 30 magasins.",
  ],
  ["answer", "Réponse"],
  ["score", "Score"],
  ["percentage", "Pourcentage"],
  ["audit-no-results", "Aucun résultat ajouté pour cette vérification."],
  ["open-pdf", "Ouvrir PDF"],
  ["open-csv", "Ouvrir CSV"],
  ["required", "Requis"],
  [
    "select-results",
    "Veuillez sélectionner les résultats que vous souhaitez voir.",
  ],
  ["files", "Files"],
  ["column-action", "Action"],
  ["email", "Email"],
  ["last", "Dernier"],
  ["january", "Janvier"],
  ["february", "Février"],
  ["march", "Mars"],
  ["april", "Avril"],
  ["may", "Mai"],
  ["june", "Juin"],
  ["july", "Juillet"],
  ["august", "Août"],
  ["september", "Septembre"],
  ["october", "Octobre"],
  ["november", "Novembre"],
  ["december", "Décembre"],
  ["of", "de"],
  ["question-missing", "Texte de la question non fourni"],
  ["answer-missing", "Aucune réponse"],
  ["range", "Plage"],
  ["min", "Min"],
  ["max", "Max"],
  [
    "range-picker-invalid",
    "La valeur minimale doit être inférieure à la valeur maximale.",
  ],
  ["from", "De"],
  ["to", "à"],
  ["no-results", "Aucun résultat"],
  ["true", "Vrai"],
  ["false", "Faux"],
  ["not-taken", "Non prise"],
  ["settings", "Paramètres"],
  ["allergens", "Allergènes"],
  ["allergen.A", "Céréales (Gluten)"],
  ["allergen.B", "Crustacés"],
  ["allergen.C", "Œuf"],
  ["allergen.D", "Poisson"],
  ["allergen.E", "Arachide"],
  ["allergen.F", "Soja"],
  ["allergen.G", "Lait"],
  ["allergen.H", "Fruits à coque"],
  ["allergen.L", "Céleri"],
  ["allergen.M", "Moutarde"],
  ["allergen.N", "Sésame"],
  ["allergen.O", "Sulfites"],
  ["allergen.P", "Lupin"],
  ["allergen.R", "Mollusques"],
  ["select-allergens", "Sélectionnez allergènes"],
  ["button-allergens-open", "Ouvrir le tableau d'allergènes"],
  ["modules-select", "Sélectionnez modules"],
  ["self-evaluation", "Auto-évaluation"],
  ["print-pdf", "Print PDF"],
  ["admin.profiling", "Profilage"],
  ["admin.task_generation", "Generation de tâche"],
  ["admin.incidents", "Incidents"],
  ["admin.chart_live", "Live"],
  ["admin.chart_last_hour", "Dernière heure"],
  [
    "admin.unable_connect_api",
    "Impossible de se connecter à l'API du serveur SecurEat.",
  ],
  [
    "audit.open-pdf-info",
    "Le PDF peut être généré avec seulement un résultat sélectionné",
  ],
  ["audit.open-csv-info", "Sélectionnez au moins un résultat"],
  ["no-condition-info", "Ajoutez une nouvelle question"],
  ["select-question", "Sélectionnez une question"],
  ["freezing", "Congélation"],
  ["element", "Élément"],
  ["type", "Type"],
  ["comment", "Commentaire"],
  ["add-question", "Ajouter question"],
  ["attachments", "Pièce jointes"],
  [
    "attachments-not-available",
    "Créez l'élement pour pouvoir mettre en ligne une pièce jointe.",
  ],
  ["no-element", "Aucun élement"],
  ["probe.chart.last_week", "Dernière semaine"],
  ["probe.chart.last_hour", "Dernière heure"],
  ["probe.chart.last_day", "Ce jour"],
  ["probe", "Sonde"],

  ["probe.upload_frequency", "Fréquence de remontée"],
  ["probe.last_temperature", "Dernière remontée"],
  ["probe.send_push_notification", "Envoi de notification push"],

  ["probes.all", "Toutes"],
  ["probes.working", "En ligne"],
  ["probes.not-working", "Hors ligne"],
  [
    "documents.file-too-big",
    "Le fichier que vous souhaitez mettre en ligne dépasse la taille maximale autorisée.",
  ],
  ["no-selection", "Aucune selection"],
  ["communication", "Communication"],
  ["communication.passed", "Passée"],
  ["communication.coming", "A venir"],
  ["communication.running", "En cours"],
  ["none", "Aucun"],
  ["tag", "Tag"],
  ["role", "Role"],
  ["communication.new_answer", "Nouvelle réponse"],
  ["communication.store_filters", "Filtres établissement"],
  ["communication.employee_filters", "Filtres employé"],
  ["overview", "Apercu"],
  ["table", "Table"],
  ["communication.question-list", "Ajouter une question ?"],
  ["everyone", "Tout le monde"],
  ["groups", "Groupes"],
  [
    "communication.no_valid_answer",
    "Selectionnez au moins une réponse valide pour chaque question.",
  ],
  ["roles", "Roles"],
  ["shop", "Ma boutique"],
  [
    "freeform-line-limit",
    "Attention, une description de plus de 5 lignes peut avoir des conséquences sur la visibilitée du message sur l'étiquette.",
  ],
  ["account", "Compte"],
  ["notify-late", "Avertir les propriétaires par email en cas de retard."],
  ["save-error", "Une erreur est survenue au moment de la sauvegarde."],
  ["change-password", "Changer le mot de passe"],
  ["new-password", "Nouveau mot de passe"],
  ["password-do-not-match", "Les mot de passes ne correspondent pas."],
  ["password-changed", "Votre mot de passe a été modifié."],
  ["date", "Date"],
  ["training", "Formation"],
]);
