import { defineStore } from "pinia";
import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import StoreElement from "@/model/Elements/StoreElement";
import Store from "@/model/Store";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import Element from "@/model/Elements/Element";
import ElementType from "@/model/ElementType";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import Probe from "@/model/Probe";

interface StoreElementState {
  initialElement: StoreElement | null;
  element: StoreElement | null;
  store: Store | null;
  creation: boolean;
  selectedType: ElementType | null;
  selectedProbe: Probe | null;
  selectedTag: KooveaTag | null;
  types: ElementType[];
  changeSaved: boolean;
}

export const getStoreElementState = defineStore("storeElementState", {
  state: (): StoreElementState => {
    return {
      initialElement: null,
      element: null,
      creation: false,
      store: null,
      selectedType: null,
      selectedTag: null,
      types: [],
      changeSaved: false,
      selectedProbe: null,
    };
  },
  actions: {
    async setup() {
      this.types = await ElementTypeManager.getAllElementsTypes(
        this.store!,
        this.getDatabaseId()
      );
    },
    getDatabaseId() {
      return this.store?.database?.id!;
    },

    set(element: Element | null, store: Store) {
      this.creation = element == null;
      this.element = null;
      this.changeSaved = false;
      this.selectedType = null;
      this.selectedProbe = null;
      this.selectedTag = null;

      if (element == null) {
        this.element = new StoreElement(
          doc(collection(Firebase.firestore, store.ref.path, "elements")),
          true,
          null,
          null,
          false,
          0,
          0,
          "",
          false,
          false,
          false,
          false,
          null,
          false,
          0,
          0,
          false,
          0,
          null,
          [],
          null
        );
      } else if (element instanceof DatabaseElement) {
        this.initialElement = StoreElement.fromOrigin(element, store);
        this.element = StoreElement.clone(this.initialElement);
      } else {
        this.initialElement = <StoreElement>element;
        this.element = StoreElement.clone(this.initialElement);
      }
      this.store = store;
    },
  },
});
